<template>
  <figure>
    <img
      src="../assets/logo.png"
      :alt="alt"
    />
  </figure>
</template>

<script>
export default {
  name: "logo",
  data() {
    return {
      alt: "sin-logo"
    };
  },
};
</script>

<style lang="scss" scoped>
figure {
  z-index: 99;
  margin: 0;
  padding: 0;
}
img {
  width: 6rem;
  height: 3rem;
}
</style>
