<template>
  <select v-model="$i18n.locale" :class="flag">
    <option
      v-for="locale in locales"
      :key="locale.id"
      :value="locale.location"
      @click="flag = locale.location"
    >
      {{ locale.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      flag: 'en',
      locales: {
      0: {
        id: 0,
        location: "en",
        name: "en",
      },
      1: {
        id: 1,
        location: "pr",
        name: "فا",
      },
    },
  }
},
};
</script>

<style lang="scss" scoped>
$glass: rgba(255, 255, 255, 0.2);
$glass-icon: rgba(255, 255, 255, 0.3);
$option: #320a28;

/* <select> styles */
select {
  z-index: 99;
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  padding: 0.2rem 3rem 0.2rem 0.2rem;
  margin: auto 1rem;

  /* Personalize */
  color: white;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);

  cursor: pointer;

  /* <option> colors */
  option {
    color: inherit;
    background-color: $option;
  }

  /* Remove focus outline */
  &:focus {
    outline: none;
  }

  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
}

.pr {
  background: url(../assets/flags/ir.svg) no-repeat right 0.5em center / 1.5em,
    linear-gradient(to left, $glass-icon 2.5em, $glass 2.5em);
}
.en {
  background: url(../assets/flags/us.svg) no-repeat right 0.5em center / 1.5em,
    linear-gradient(to left, $glass-icon 2.5em, $glass 2.5em);
}
</style>
