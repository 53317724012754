<template>
  <div class="container-flex">
    <div v-for="project in projects" :key="project.id" class="project">
      <img :src="project.img" :alt="project.alt" />
      <h2>{{ project.title }}</h2>
      <p>
        {{ project.desc }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      projects: {
        0: {
          id: 0,
          title: "Title 1",
          alt: "Title 1 Photo",
          img: require('@/assets/img/projects/Login-Page.png'),
          desc: "Description of Project 10. Add details about your role, challenges, and outcomes.",
        },
        1: {
          id: 1,
          title: "Title 2",
          alt: "Title 2 Photo",
          img: require('@/assets/img/projects/Login-Page.png'),
          desc: "Description of Project 20. Add details about your role, challenges, and outcomes.",
        },
        2: {
          id: 2,
          title: "Title 3",
          alt: "Title 3 Photo",
          img: require('@/assets/img/projects/Login-Page.png'),
          desc: "Description of Project 30. Add details about your role, challenges, and outcomes.",
        },
        3: {
          id: 3,
          title: "Title 4",
          alt: "Title 4 Photo",
          img: require('@/assets/img/projects/Login-Page.png'),
          desc: "Description of Project 40. Add details about your role, challenges, and outcomes.",
        },
      },
    };
  },
  components: {
    //
  },
};
</script>

<style scoped lang="scss">
.project {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 1.3rem;
  margin: auto;
  max-width: 25rem;
}

.project img {
  max-width: 20rem;
  height: auto;
}

.container-flex {
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
</style>
