<template>
    <div>
      <img alt="Vue logo" src="../assets/logo.png">
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeView',
    components: {
      // 
    }
  }
  </script>
    
<style>
img {
  width: 100vw;
}
</style>