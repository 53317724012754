<template>
  <label class="hamburger">
    <input type="checkbox"/>
    <svg viewBox="0 0 32 32" @click="hamClick">
      <path
        class="hamburger__line hamburger__line-top-bottom"
        d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
      ></path>
      <path class="hamburger__line" d="M7 16 27 16"></path>
    </svg>
  </label>
</template>

<script>
export default {
  name: "hamburger-menu",
  methods: {
    hamClick() {
      this.$emit('updateMenuValue', this.isShow = !this.isShow);
    },
  },
};
</script>

<style lang="scss">
$dark: #0b232f;
.hamburger {
  display: none;
  z-index: 99;
  cursor: pointer;
  input {
    display: none;
    &:checked + svg {
      transform: rotate(-45deg);
    }
    &:checked + svg .hamburger__line-top-bottom {
      stroke-dasharray: 20 300;
      stroke-dashoffset: -32.42;
    }
  }
  svg {
    /* The size of the SVG defines the overall size */
    height: 3em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}
.hamburger__line {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  /* Define the transition for transforming the Stroke */
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  &-top-bottom {
    stroke-dasharray: 12 63;
  }
}

@media screen and (max-width: 767px) {
  .hamburger {
    display: block;
  }
  nav {
    .menu {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 90;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 1rem 0;
      width: 100vw;
      height: 100vh;
      font-size: 2rem;
      background: $dark;
      transition: opacity 0.1s .2s ease-in-out, clip-path 1s 0.21s ease-in-out;
      opacity: 0;
      clip-path: circle(110px at top center);
    }
  }
}
</style>
