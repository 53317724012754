<template>
  <div class="background">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<style scoped>
@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  z-index: -10;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #16262e;
  overflow: hidden;
}

.background span {
  width: 4vmin;
  height: 4vmin;
  border-radius: 4vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 37;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.background span:nth-child(0) {
  color: #b98ea7;
  top: 57%;
  left: 100%;
  animation-duration: 131s;
  animation-delay: -93s;
  transform-origin: 14vw -12vh;
  box-shadow: 8vmin 0 1.6360581334173794vmin currentColor;
}
.background span:nth-child(1) {
  color: #2e4756;
  top: 50%;
  left: 26%;
  animation-duration: 91s;
  animation-delay: -85s;
  transform-origin: -1vw -6vh;
  box-shadow: -8vmin 0 1.6975792226592956vmin currentColor;
}
.background span:nth-child(2) {
  color: #3c7a89;
  top: 31%;
  left: 63%;
  animation-duration: 108s;
  animation-delay: -57s;
  transform-origin: -20vw -17vh;
  box-shadow: -8vmin 0 1.1606102086566144vmin currentColor;
}
.background span:nth-child(3) {
  color: #e6c79c;
  top: 33%;
  left: 80%;
  animation-duration: 76s;
  animation-delay: -85s;
  transform-origin: -4vw 21vh;
  box-shadow: 8vmin 0 1.6494559240059052vmin currentColor;
}
.background span:nth-child(4) {
  color: #e6c79c;
  top: 8%;
  left: 67%;
  animation-duration: 108s;
  animation-delay: -112s;
  transform-origin: -6vw -22vh;
  box-shadow: 8vmin 0 1.8409461406141772vmin currentColor;
}
.background span:nth-child(5) {
  color: #e6c79c;
  top: 26%;
  left: 35%;
  animation-duration: 83s;
  animation-delay: -111s;
  transform-origin: 13vw -3vh;
  box-shadow: 8vmin 0 1.9637172393215587vmin currentColor;
}
.background span:nth-child(6) {
  color: #b98ea7;
  top: 56%;
  left: 55%;
  animation-duration: 73s;
  animation-delay: -99s;
  transform-origin: 18vw -21vh;
  box-shadow: 8vmin 0 1.254004542238624vmin currentColor;
}
.background span:nth-child(7) {
  color: #b98ea7;
  top: 96%;
  left: 37%;
  animation-duration: 86s;
  animation-delay: -2s;
  transform-origin: 18vw 24vh;
  box-shadow: -8vmin 0 1.4842471302383113vmin currentColor;
}
.background span:nth-child(8) {
  color: #3c7a89;
  top: 58%;
  left: 84%;
  animation-duration: 36s;
  animation-delay: -59s;
  transform-origin: -2vw 2vh;
  box-shadow: 8vmin 0 1.329063139137483vmin currentColor;
}
.background span:nth-child(9) {
  color: #2e4756;
  top: 14%;
  left: 44%;
  animation-duration: 84s;
  animation-delay: -122s;
  transform-origin: 18vw 8vh;
  box-shadow: -8vmin 0 1.1730858526429266vmin currentColor;
}
.background span:nth-child(10) {
  color: #2e4756;
  top: 24%;
  left: 8%;
  animation-duration: 20s;
  animation-delay: -52s;
  transform-origin: 18vw -2vh;
  box-shadow: 8vmin 0 1.0525511097401594vmin currentColor;
}
.background span:nth-child(11) {
  color: #3c7a89;
  top: 59%;
  left: 94%;
  animation-duration: 77s;
  animation-delay: -109s;
  transform-origin: -1vw 6vh;
  box-shadow: 8vmin 0 1.5132915300236989vmin currentColor;
}
.background span:nth-child(12) {
  color: #e6c79c;
  top: 37%;
  left: 55%;
  animation-duration: 113s;
  animation-delay: -94s;
  transform-origin: 9vw -19vh;
  box-shadow: 8vmin 0 1.0222136607615417vmin currentColor;
}
.background span:nth-child(13) {
  color: #b98ea7;
  top: 82%;
  left: 53%;
  animation-duration: 81s;
  animation-delay: -104s;
  transform-origin: -12vw -5vh;
  box-shadow: 8vmin 0 1.3286902943994774vmin currentColor;
}
.background span:nth-child(14) {
  color: #e6c79c;
  top: 92%;
  left: 44%;
  animation-duration: 123s;
  animation-delay: -107s;
  transform-origin: 23vw 14vh;
  box-shadow: 8vmin 0 1.159986507749555vmin currentColor;
}
.background span:nth-child(15) {
  color: #b98ea7;
  top: 44%;
  left: 35%;
  animation-duration: 11s;
  animation-delay: -90s;
  transform-origin: -20vw -3vh;
  box-shadow: -8vmin 0 1.6561244521702783vmin currentColor;
}
.background span:nth-child(16) {
  color: #b98ea7;
  top: 14%;
  left: 84%;
  animation-duration: 134s;
  animation-delay: -76s;
  transform-origin: 22vw -22vh;
  box-shadow: 8vmin 0 1.016726124883461vmin currentColor;
}
.background span:nth-child(17) {
  color: #3c7a89;
  top: 21%;
  left: 24%;
  animation-duration: 63s;
  animation-delay: -76s;
  transform-origin: -11vw -20vh;
  box-shadow: -8vmin 0 1.4895935219028118vmin currentColor;
}
.background span:nth-child(18) {
  color: #e6c79c;
  top: 40%;
  left: 58%;
  animation-duration: 38s;
  animation-delay: -16s;
  transform-origin: 22vw -17vh;
  box-shadow: 8vmin 0 1.2236196971476885vmin currentColor;
}
.background span:nth-child(19) {
  color: #3c7a89;
  top: 44%;
  left: 7%;
  animation-duration: 42s;
  animation-delay: -23s;
  transform-origin: -2vw -19vh;
  box-shadow: 8vmin 0 1.4004578636874006vmin currentColor;
}
.background span:nth-child(20) {
  color: #b98ea7;
  top: 58%;
  left: 23%;
  animation-duration: 59s;
  animation-delay: -53s;
  transform-origin: -13vw 15vh;
  box-shadow: -8vmin 0 1.675845546733124vmin currentColor;
}
.background span:nth-child(21) {
  color: #b98ea7;
  top: 71%;
  left: 24%;
  animation-duration: 125s;
  animation-delay: -21s;
  transform-origin: -5vw -4vh;
  box-shadow: 8vmin 0 1.4810172899485972vmin currentColor;
}
.background span:nth-child(22) {
  color: #3c7a89;
  top: 58%;
  left: 7%;
  animation-duration: 8s;
  animation-delay: -70s;
  transform-origin: -7vw -1vh;
  box-shadow: -8vmin 0 1.222639929481881vmin currentColor;
}
.background span:nth-child(23) {
  color: #e6c79c;
  top: 54%;
  left: 55%;
  animation-duration: 127s;
  animation-delay: -111s;
  transform-origin: 15vw 17vh;
  box-shadow: -8vmin 0 1.7329700764253795vmin currentColor;
}
.background span:nth-child(24) {
  color: #b98ea7;
  top: 12%;
  left: 10%;
  animation-duration: 47s;
  animation-delay: -83s;
  transform-origin: 6vw -20vh;
  box-shadow: 8vmin 0 1.8284258062708463vmin currentColor;
}
.background span:nth-child(25) {
  color: #b98ea7;
  top: 68%;
  left: 66%;
  animation-duration: 52s;
  animation-delay: -92s;
  transform-origin: -17vw 14vh;
  box-shadow: -8vmin 0 1.8943781924534224vmin currentColor;
}
.background span:nth-child(26) {
  color: #2e4756;
  top: 48%;
  left: 2%;
  animation-duration: 81s;
  animation-delay: -114s;
  transform-origin: -23vw -2vh;
  box-shadow: 8vmin 0 1.8483534614508776vmin currentColor;
}
.background span:nth-child(27) {
  color: #3c7a89;
  top: 4%;
  left: 82%;
  animation-duration: 31s;
  animation-delay: -25s;
  transform-origin: -15vw 13vh;
  box-shadow: -8vmin 0 1.354175189957997vmin currentColor;
}
.background span:nth-child(28) {
  color: #3c7a89;
  top: 34%;
  left: 49%;
  animation-duration: 49s;
  animation-delay: -72s;
  transform-origin: 22vw -9vh;
  box-shadow: 8vmin 0 1.7542029015605418vmin currentColor;
}
.background span:nth-child(29) {
  color: #3c7a89;
  top: 81%;
  left: 31%;
  animation-duration: 84s;
  animation-delay: -34s;
  transform-origin: 8vw 14vh;
  box-shadow: 8vmin 0 1.2197523517186464vmin currentColor;
}
.background span:nth-child(30) {
  color: #b98ea7;
  top: 50%;
  left: 22%;
  animation-duration: 59s;
  animation-delay: -27s;
  transform-origin: -11vw 23vh;
  box-shadow: -8vmin 0 1.497984860617084vmin currentColor;
}
.background span:nth-child(31) {
  color: #3c7a89;
  top: 47%;
  left: 70%;
  animation-duration: 58s;
  animation-delay: -22s;
  transform-origin: -9vw -6vh;
  box-shadow: 8vmin 0 1.984928242117168vmin currentColor;
}
.background span:nth-child(32) {
  color: #e6c79c;
  top: 11%;
  left: 60%;
  animation-duration: 91s;
  animation-delay: -33s;
  transform-origin: -3vw 23vh;
  box-shadow: -8vmin 0 1.6322124302268vmin currentColor;
}
.background span:nth-child(33) {
  color: #3c7a89;
  top: 78%;
  left: 5%;
  animation-duration: 39s;
  animation-delay: -121s;
  transform-origin: -2vw -24vh;
  box-shadow: 8vmin 0 1.4445908085046693vmin currentColor;
}
.background span:nth-child(34) {
  color: #3c7a89;
  top: 92%;
  left: 32%;
  animation-duration: 131s;
  animation-delay: -73s;
  transform-origin: -4vw -5vh;
  box-shadow: -8vmin 0 1.9738461922688566vmin currentColor;
}
.background span:nth-child(35) {
  color: #3c7a89;
  top: 6%;
  left: 90%;
  animation-duration: 84s;
  animation-delay: -113s;
  transform-origin: -17vw -15vh;
  box-shadow: 8vmin 0 1.0765506050485159vmin currentColor;
}
.background span:nth-child(36) {
  color: #3c7a89;
  top: 63%;
  left: 76%;
  animation-duration: 97s;
  animation-delay: -93s;
  transform-origin: -13vw 19vh;
  box-shadow: -8vmin 0 1.097557302886949vmin currentColor;
}
.background span:nth-child(37) {
  color: #2e4756;
  top: 46%;
  left: 97%;
  animation-duration: 119s;
  animation-delay: -98s;
  transform-origin: 12vw 16vh;
  box-shadow: -8vmin 0 1.221671547738681vmin currentColor;
}
.background span:nth-child(38) {
  color: #e6c79c;
  top: 92%;
  left: 35%;
  animation-duration: 72s;
  animation-delay: -97s;
  transform-origin: 0vw 3vh;
  box-shadow: 8vmin 0 1.9964976372299588vmin currentColor;
}
.background span:nth-child(39) {
  color: #e6c79c;
  top: 75%;
  left: 28%;
  animation-duration: 6s;
  animation-delay: -55s;
  transform-origin: -3vw -20vh;
  box-shadow: -8vmin 0 1.2059349185225434vmin currentColor;
}
.background span:nth-child(40) {
  color: #b98ea7;
  top: 73%;
  left: 14%;
  animation-duration: 113s;
  animation-delay: -56s;
  transform-origin: 20vw -12vh;
  box-shadow: -8vmin 0 1.8387878474390345vmin currentColor;
}
.background span:nth-child(41) {
  color: #b98ea7;
  top: 88%;
  left: 79%;
  animation-duration: 6s;
  animation-delay: -126s;
  transform-origin: 11vw 2vh;
  box-shadow: 8vmin 0 1.388628998757069vmin currentColor;
}
</style>
