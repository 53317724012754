export default {
  "home-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحه اصلی"])},
  "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پرو‌ژه‌ها"])},
  "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رزومه"])},
  "send-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال درخواست"])},
  "sina-sangjiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سینا"])},
  "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلام"])},
  "im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سینا هستم"])},
  "ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهندس کامپیوتر"])},
  "fswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامه‌نویس فول‌استک وب"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متخصص دِو‌آپس"])},
  "d&d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طراحی و توسعه از سینـ"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["۲۰۲۰-۲۴"])},
  "test1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تست۱"])},
  "test2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تست۲"])}
}