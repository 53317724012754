<template>
  <section class="home">
    <section class="intro">
      <p>{{ $t("greeting") }} 👋</p>
      <p>{{ $t("im") }}</p>
      <section class="intro__animation">
        <div class="intro__first">
          <div>{{ $t("ce") }}</div>
        </div>
        <div class="intro__second">
          <div>{{ $t("fswd") }}</div>
        </div>
        <div class="intro__third">
          <div>{{ $t("de") }}</div>
        </div>
      </section>
    </section>
    <section class="pic">
      <figure class="avatar">
        <img src="../assets/sina.jpeg" alt="Sina Photo" />
      </figure>
    </section>
  </section>
</template>

<script>
export default {
  name: "HomeView",
  components: {
    //
  },
};
</script>

<style lang="scss" scoped>
$web1: #20a7d8;
$web2: #cd921e;
$web3: #c10528;

$baseFontSize: 2rem; // Set your base font size
$maxFontSize: 3rem; // Set the maximum font size

$animationDuration: 4s;

.home {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  // height: 100vh;
  justify-content: space-between;
  align-items: center;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  font-size: max(#{$baseFontSize}, min(1.5vw, #{$maxFontSize}));
  p {
    margin: 0;
    text-shadow: 0 0 7px rgba(255, 255, 255, 0.3),
      0 0 3px rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
  }
}

.intro__animation {
  margin-top: 2rem;
  height: 4.5rem;
  overflow: hidden;
  font-size: 1.3rem;
}

.intro__animation > div > div {
  padding: 1rem 1rem;
  // height: 4rem;
  margin-bottom: 3.5rem;
  display: inline-block;
}

.intro__animation div:first-child {
  animation: text-animation 8s infinite;
}

.intro__first div {
  background-color: $web1;
}

.intro__second div {
  background-color: $web2;
}

.intro__third div {
  background-color: $web3;
}

.avatar {
  figure {
    margin: auto;
  }
  img {
    width: 50vw;
    border-radius: 50%;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);

    background-color: #007bff;
    animation: moveBorderRadius $animationDuration linear infinite;
  }
}

@media (min-width: 640px) {
  .home {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .avatar {
    img {
      width: 40vw;
    }
  }
}

@media (min-width: 1024px) {
  .avatar {
    img {
      width: 35vw;
    }
  }
  .intro__animation {
    font-size: 2rem;
  }
  .intro__animation > div > div {
  padding: 1rem 1rem;
  margin-bottom: 2.5rem;
  }
}

@keyframes moveBorderRadius {
  0% {
    border-radius: 27% 73% 41% 59% / 52% 25% 75% 48%;
  }
  50% {
    border-radius: 50% 33% 59% 41% / 52% 25% 75% 48%;
  }
  100% {
    border-radius: 27% 73% 41% 59% / 52% 25% 75% 48%;
  }
}

.intro,
.pic {
  margin: auto;
}

@keyframes text-animation {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  20% {
    margin-top: -5.62rem;
  }

  30% {
    margin-top: -5.62rem;
  }

  40% {
    margin-top: -11.24rem;
  }

  60% {
    margin-top: -11.24rem;
  }

  70% {
    margin-top: -5.62rem;
  }

  80% {
    margin-top: -5.62rem;
  }

  90% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}
</style>
