<template>
  <footer>
    <p>{{ $t("d&d") }} ;)</p>
    <p>&copy; {{ $t("copy") }}</p>
  </footer>
</template>

<script>

</script>

<style lang="scss" scoped>
$dark: rgb(44, 62, 80, .3);

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  border-radius: 99rem 99rem 50rem 50rem;

  padding: 1rem 0;
  // margin-top: auto;
  background-color: $dark;
  p {
    margin: 0;
  }
}
</style>
