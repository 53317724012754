<template>
  <background />
  <section class="base">
    <nav>
      <logo/>
      <menu-icon @updateMenuValue="updateMenuValue"/>
      <menu class="menu" :class="{ 'show-menu': isShow, 'big-menu': isBig }">
        <router-link @click="hideMenu" to="/"> {{ $t("home-page") }} </router-link>
        <router-link @click="hideMenu" to="/projects"> {{ $t("projects") }} </router-link>
        <router-link @click="hideMenu" to="/resume"> {{ $t("resume") }} </router-link>
        <router-link @click="hideMenu" to="/send"> {{ $t("send-request") }} </router-link>
      </menu>
      <LocaleSwitcher />
    </nav>

    <main class="container">
      <router-view />
    </main>

    <copy-right />
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import background from "@/components/background.vue";
import logo from "@/components/logo.vue";
import CopyRight from "@/components/footer.vue";
import MenuIcon from "@/components/MenuIcon.vue";

export default {
  name: "App",
  data() {
    return {
      isShow: false,
    };
  },
  components: {
    LocaleSwitcher,
    background,
    logo,
    MenuIcon,
    CopyRight,
  },
  setup() {
    console.log("init");
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    // Something todo ..
    return { t };
  },
  methods: {
    updateMenuValue(newValue) {
      this.isShow = newValue; // Update the appValue variable
    },
    hideMenu() {
      setTimeout(() => {
        this.isShow = false;
      }, 100);
    },
  },
};
</script>

<style lang="scss">
$main: #deeeff;
$nav-link: #f3f9ff;
$dark: #2c3e50;
$green: #42b983;
$greenHover: #6cffbd;

#app {
  font-family: Avenir, Vazirmatn, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $main;
  width: 100%;
  height: 100%;
  height: 98vh;
}

.base {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  main {
    margin: auto;
  }
}

// option {
  //   font-family: Avenir, Vazirmatn, sans-serif;
  // }
  
  .container {
    width: 100%;
    margin: 1rem auto;
    overflow-y: scroll;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  height: 65px;
  padding: 0 2vw;
  margin: 0 auto;
  background: $dark;
  border-radius: 2rem;
  a {
    position: relative;
    font-weight: bold;
    color: $nav-link;
    text-decoration: none;
    margin: 0.2rem;
    padding: 0 0.5rem;

    &:hover {
      color: $greenHover;
      transition: ease-in-out 100ms;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: $greenHover;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &.router-link-exact-active {
      color: $green;
    }
  }
  menu {
    display: flex;
    margin: auto;
  }
}
// @media screen and (max-width: 767px) {
//   .show-menu {

//   }
// }
.show-menu {
  opacity: 1 !important;
  clip-path: circle(100% at center) !important;
}
</style>
