export default {
  "home-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
  "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
  "send-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Request"])},
  "sina-sangjiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s-inax"])},
  "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi"])},
  "im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm"])},
  "ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer Engineer"])},
  "fswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Stack Web Developer"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps Engineer"])},
  "d&d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design & Develop By ME"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020-24"])},
  "test1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test1"])},
  "test2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test2"])}
}